/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  BarChartOutlined,
  DeploymentUnitOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import './App.css'
import { Card, Col, Layout, Row, Space } from 'antd'
import { Content, Footer } from 'antd/es/layout/layout'
import Menu from './components/menu'
import locales from './locales/locale'
import rubinetto from './images/rubinetto.png'
import componente from './images/compSemiLavorato.png'
import team from './images/team.png'
import { useState } from 'react'

function App() {
  const [language, setLanguage] = useState(locales.getLanguage())

  return (
    <Layout>
      <Menu setLang={setLanguage} lang={language} />
      <Content style={{ zIndex: 1 }}>
        <div className="background">
          <Space
            className="primoParagrafo"
            direction="vertical"
            align="center"
            size="small"
          >
            <h2>Factory solution</h2>
            <p>{locales.paragrafo1}</p>
          </Space>
          <svg
            className="white-svg"
            viewBox="0 0 100 10"
            preserveAspectRatio="none"
          >
            <path d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"></path>
          </svg>
        </div>
        <Row
          style={{ alignItems: 'center' }}
          className="card-container"
          gutter={[16, 16]}
        >
          <Col style={{ maxWidth: '100%' }} span={8}>
            <Card
              title={
                <Space
                  className="titolo-card"
                  align="center"
                  direction="vertical"
                >
                  <BarChartOutlined />
                  <b className="bluScuro">
                    {locales.paragrafo2.riquadro1.titolo}
                  </b>
                </Space>
              }
            >
              <span>{locales.paragrafo2.riquadro1.descrizione}</span>
            </Card>
          </Col>
          <Col style={{ maxWidth: '100%' }} span={8}>
            <Card
              title={
                <Space
                  className="titolo-card"
                  align="center"
                  direction="vertical"
                >
                  <DeploymentUnitOutlined />
                  <b className="bluScuro">
                    {locales.paragrafo2.riquadro2.titolo}
                  </b>
                </Space>
              }
            >
              <span>{locales.paragrafo2.riquadro1.descrizione}</span>
            </Card>
          </Col>
          <Col style={{ maxWidth: '100%' }} span={8}>
            <Card
              title={
                <Space
                  className="titolo-card"
                  align="center"
                  direction="vertical"
                >
                  <EnvironmentOutlined />
                  <b className="bluScuro">
                    {locales.paragrafo2.riquadro3.titolo}
                  </b>
                </Space>
              }
            >
              <span>
                {locales.paragrafo2.riquadro3.descrizione} <br />
                <br />
              </span>
            </Card>
          </Col>
        </Row>
        <div
          className="paragrafo"
          style={{
            height: '120vh',
          }}
        >
          <div>
            <h1>{locales.paragrafo3.titolo}</h1>
            <p>{locales.paragrafo3.descrizione}</p>
          </div>
          <img alt="By Karolina Grabowska" src={team} />
        </div>
        <div className="paragrafo sfondoBianco reverse noPadding">
          <div>
            <h1>{locales.paragrafo4.titolo}</h1>
            <p>{locales.paragrafo4.descrizione}</p>
          </div>
          <div className="analisi" />
        </div>
        <div
          className="paragrafo reverse"
          style={{
            flexDirection: 'column',
            flexWrap: 'nowrap',
          }}
        >
          <div style={{ width: '90%' }}>
            <h1>{locales.paragrafo5Titolo}</h1>
          </div>
          <div className="card-finali">
            <Card
              size="small"
              bordered={false}
              style={{ width: '55vh' }}
              cover={<img alt="By Karolina Grabowska" src={rubinetto} />}
            >
              <b className="bluScuro">{locales.paragrafo5.riquadro1.titolo}</b>
              <p> {locales.paragrafo5.riquadro1.descrizione}</p>
            </Card>
            <Card
              size="small"
              bordered={false}
              style={{ width: '55vh' }}
              cover={<img alt="By Tima Miroshnichenko" src={componente} />}
            >
              <b className="bluScuro">{locales.paragrafo5.riquadro2.titolo}</b>
              <p> {locales.paragrafo5.riquadro2.descrizione}</p>
            </Card>
          </div>
        </div>
      </Content>
      <Footer>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <b>{locales.contact}</b>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center' }}>
          <Col>
            <Space
              style={{
                borderRight: '1px #ffffff3d solid',
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <PhoneOutlined />
              <span>+359 877213118</span>
            </Space>
          </Col>
          <Col>
            <Space
              style={{
                padding: '0 1vw',
              }}
              size={'small'}
            >
              <MailOutlined />
              <a
                href="mailto:info@factorysolutionltd.com"
                style={{ color: 'white' }}
              >
                info@factorysolutionltd.com
              </a>
            </Space>
          </Col>
        </Row>
        <Row style={{ placeContent: 'center', marginTop: '1vw' }}>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              target="_blank"
              href="https://www.termsfeed.com/live/2147d4ae-3ed0-4b93-b7c8-847fe8eb1015"
              rel="noreferrer"
            >
              Privacy policy
            </a>
          </Col>
          <Col
            style={{
              padding: '0 1vw',
            }}
          >
            <a
              target="_blank"
              href="https://www.cookiepolicygenerator.com/live.php?token=FwSxVjkZgKJ785FIUZgelHyxjoxcWBBH"
              rel="noreferrer"
            >
              Cookie policy
            </a>
          </Col>
        </Row>
      </Footer>
    </Layout>
  )
}

export default App
