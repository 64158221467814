import LocalizedStrings from 'react-localization'

let locales = new LocalizedStrings({
  en: {
    paragrafo1:
      'Factory solution  specializes in the wholesale distribution of high-quality materials for the manufacturing of taps and semi-finished products essential for the production of industrial machinery. With a focus on reliability and efficiency, we provide a vast range of composite materials tailored to meet the specific needs of our diverse clientele, ranging from small fabricators to large-scale manufacturers. Our commitment to maintaining a robust supply chain and offering competitive pricing ensures that our clients receive the best value and timely delivery, empowering their production capabilities and contributing to their operational success.',
    paragrafo2: {
      riquadro1: {
        titolo: 'Strategic Analysis',
        descrizione:
          'Factory Solution provides expert market analysis to guide your business strategy, offering clear insights into global trends.',
      },
      riquadro2: {
        titolo: 'Global Trade',
        descrizione:
          'Factory Solution connects markets efficiently and facilitates the exchange of goods with an effective and targeted strategy.',
      },
      riquadro3: {
        titolo: 'Operational Optimisation',
        descrizione:
          'Factory Solution optimises the handling of goods, ensuring fast and secure logistics.',
      },
    },
    paragrafo3: {
      titolo:
        'At the Core of Factory Solution, Customised Commercial Solutions.',
      descrizione:
        'Our team consists of seasoned professionals with extensive experience in the field of commercial buying and selling. Guided by a shared vision, our expertise is the basis of our success, ensuring efficient, ethical and profitable operations for our customers and partners.',
    },
    paragrafo4: {
      titolo: 'Market Analysis',
      descrizione:
        "Gain a competitive edge with Factory Solution's market analyses. Our experts provide in-depth perspectives on industry trends and market dynamics, giving you the knowledge you need to navigate the complexities of global trade.",
    },

    paragrafo5: {
      riquadro1: {
        titolo: 'Taps and Fittings',
        descrizione:
          'Factory Solution facilitates international trade in tap materials, ensuring a reliable global supply to meet the needs of the plumbing industry.',
      },
      riquadro2: {
        titolo: 'Semi-Finished Materials',
        descrizione:
          'Factory Solution provides essential semi-finished materials for the production of industrial machinery, connecting markets and facilitating the supply chain for the manufacturing industry.',
      },
    },
    paragrafo5Titolo:
      'Factory Solution specialises in the wholesale of tap materials and the supply of semi-finished components for the production of industrial machinery, meeting a wide range of global demands.',

    paragrafo6: {
      titolo: 'Market Analysis',
      descrizione:
        "Gain a competitive edge with Factory Solution's market analyses. Our experts provide in-depth perspectives on industry trends and market dynamics, giving you the knowledge you need to navigate the complexities of global trade.",
    },
    contact: 'Contact us',
  },
  bg: {
    paragrafo1:
      'Factory solution е специализирана в дистрибуцията на едро на висококачествени материали за производство на кранове и полуфабрикати, необходими за производството на промишлени машини. С акцент върху надеждността и ефективността, ние предлагаме широка гама от композитни материали, съобразени със специфичните нужди на разнообразната ни клиентела, варираща от малки производители до големи производители. Ангажиментът ни да поддържаме стабилна верига за доставки и да предлагаме конкурентни цени гарантира, че нашите клиенти получават най-добрата стойност и навременна доставка, като разширяваме производствените им възможности и допринасяме за техния оперативен успех.',
    paragrafo2: {
      riquadro1: {
        titolo: 'Стратегически анализ',
        descrizione:
          'Factory Solution предоставя експертен пазарен анализ, който да направлява вашата бизнес стратегия, като предлага ясен поглед върху глобалните тенденции.',
      },
      riquadro2: {
        titolo: 'Глобална търговия',
        descrizione:
          'Factory Solution свързва ефективно пазарите и улеснява обмена на стоки с ефективна и целенасочена стратегия.',
      },
      riquadro3: {
        titolo: 'Оперативна оптимизация',
        descrizione:
          'Factory Solution оптимизира обработката на стоки, като осигурява бърза и сигурна логистика.',
      },
    },
    paragrafo3: {
      titolo:
        'В основата на Factory Solution са персонализираните търговски решения.',
      descrizione:
        'Нашият екип се състои от опитни професионалисти с богат опит в областта на покупко-продажбите на търговски обекти. Водени от споделена визия, нашите експертни познания са в основата на успеха ни, като осигуряват ефективни, етични и печеливши операции за нашите клиенти и партньори.',
    },
    paragrafo4: {
      titolo: 'Анализ на пазара',
      descrizione:
        'Спечелете конкурентно предимство с пазарните анализи на Factory Solution. Нашите експерти предоставят задълбочени гледни точки за тенденциите в индустрията и динамиката на пазара, като ви дават знанията, от които се нуждаете, за да се ориентирате в сложността на глобалната търговия.',
    },

    paragrafo5: {
      riquadro1: {
        titolo: 'Кранчета и фитинги',
        descrizione:
          'Factory Solution улеснява международната търговия с продукти за водопроводни кранове, като осигурява надеждни доставки в световен мащаб, за да отговори на нуждите на водопроводната индустрия.',
      },
      riquadro2: {
        titolo: 'Полуготови компоненти',
        descrizione:
          'Factory Solution осигурява основни полуготови компоненти за производството на промишлени машини, като свързва пазарите и улеснява веригата на доставки за производствената индустрия.',
      },
    },
    paragrafo5Titolo:
      'Factory Solution е специализирана в търговията на едро с продукти за кранове и доставката на полуготови компоненти за производството на промишлени машини, като отговаря на широк спектър от глобални изисквания.',
    paragrafo6: {
      titolo: 'Анализ на пазара',
      descrizione:
        'Спечелете конкурентно предимство с пазарните анализи на Factory Solution. Нашите експерти предоставят задълбочени гледни точки за тенденциите в индустрията и динамиката на пазара, като ви дават знанията, от които се нуждаете, за да се ориентирате в сложността на глобалната търговия.',
    },
    contact: 'Свържете се с нас',
  },
})

export default locales
