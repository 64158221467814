import {
  FieldTimeOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import { Space, Switch } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { memo, useEffect, useState } from 'react'
import logo from '../images/logo_bianco.png'
import './../App.css'
import locales from '../locales/locale'

const Menu = ({
  setLang,
  lang,
}: {
  setLang: React.Dispatch<React.SetStateAction<string>>
  lang: string
}): JSX.Element => {
  const [isFixed, setIsFixed] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      setIsFixed(scrollTop > 0) // Imposta isFixed a true se lo scroll è maggiore di 0
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isFixedClassName = isFixed ? 'is-fixed' : ''

  return (
    <Header id="header" className={isFixedClassName}>
      <div className={`info-menu ${isFixedClassName}`}>
        <div style={{ float: 'right' }}>
          <Space
            style={{
              borderRight: '1px #ffffff3d solid',
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <PhoneOutlined />
            <span>+359 877213118</span>
          </Space>
          <Space
            style={{
              padding: '0 1vw',
            }}
            size={'small'}
          >
            <MailOutlined />
            <a
              href="mailto:info@factorysolutionltd.com"
              style={{ color: 'white' }}
            >
              info@factorysolutionltd.com
            </a>
          </Space>
        </div>
      </div>
      <Space
        direction="horizontal"
        align="start"
        style={{
          padding: 5,
          color: isFixed ? 'black' : 'white',
        }}
      >
        <img src={logo} alt="Sfondo" className={`logo ${isFixedClassName}`} />
        <div className="text-logo">
          <b>Factory</b> <span>solution</span>
        </div>
      </Space>
      <Switch
        className={`lingua ${isFixedClassName}`}
        checked={lang === 'en'}
        onChange={(language) => {
          setLang(language ? 'en' : 'bg')
          locales.setLanguage(language ? 'en' : 'bg')
        }}
        checkedChildren="EN"
        unCheckedChildren="BG"
      />
    </Header>
  )
}

export default memo(Menu)
